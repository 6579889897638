/** Version: 2024-12-20 */

const CONSENT_STORAGE_KEY = "consentData";

const html = `
<div class="cookie-consent-popup border-top shadow py-3 fixed-bottom bg-white" style="max-height: 100vh; overflow-y:auto;">
  <div class="container">
  <div>
    <a class="btn-cookie-content-later"  href="#" onclick="return consentLater()">Continuer sans accepter</a>
  </div>
  <p class="mt-2 mb-0 lead font-weight-bold"><strong>Vos préférences en matière de cookies</strong></p>
  <p class="mb-0 small">
    Nous utilisons des cookies et des technologies de suivi (tels que les tags Google) afin d’améliorer votre expérience, de mesurer l’audience de notre site, ainsi que d’évaluer l’efficacité de nos campagnes marketing. Vous pouvez accepter ou refuser ces cookies, et modifier vos préférences à tout moment.
  </p>
  <details class="mb-2">
    <summary class="mt-2 text-primary">En savoir plus</summary>
    <div class="pl-3">
    <p class="mt-2 mb-0"><strong>Cookies Nécessaires (Toujours activés)</strong></p>
    <p>Nécessaires au bon fonctionnement du site. Ils assurent la sécurité, la connexion, la sauvegarde de vos préférences essentielles, et ne peuvent pas être désactivés.</p>
    <p class="mb-0"><strong>Mesure d’audience (Google Analytics)</strong></p>
    <p class="mb-0">Nous aident à comprendre comment vous interagissez avec notre site, à améliorer l’expérience utilisateur et à mesurer la performance de nos contenus. En acceptant, vous nous permettez de collecter des données anonymisées sur votre navigation.</p>
    <p class="small">En acceptant, vous consentez à l’utilisation des cookies et au traitement de vos données personnelles selon les <a href="https://business.safety.google/privacy/" target="_blank">Règles de confidentialité de Google</a>.</p>
    <p class="mb-0"><strong>Marketing (Google Ads et partenaires)</strong></p>
    <p class="mb-0">Nous permettent de comprendre l’efficacité de nos campagnes marketing externes (par exemple, lorsque vous cliquez sur nos annonces sur d’autres plateformes) et d’optimiser nos actions pour améliorer la qualité des leads obtenus. Aucune publicité n’est directement affichée sur ce site, mais ces données nous aident à mieux cibler nos efforts marketing.</p>
    <p class="small">En acceptant, vous consentez à l’utilisation des cookies et au traitement de vos données personnelles selon les <a href="https://business.safety.google/privacy/" target="_blank">Règles de confidentialité de Google</a>.</p>
    </div>
  </details>
  <form onsubmit="return consentFormSubmit()">
    <div class="d-md-flex align-items-center">
      <div class="mr-3">
        <label class="d-block d-sm-inline-block me-2 pb-0 mb-0"><input type="checkbox" name="necessary" checked disabled /> Necessaires</label>
        <label class="d-block d-sm-inline-block me-2 pb-0 mb-0"><input type="checkbox" name="analytics" /> Mesure d'audience</label>
        <label class="d-block d-sm-inline-block me-2 pb-0 mb-0"><input type="checkbox" name="marketing" /> Marketing</label>
      </div>
      <div class="mt-2 mt-md-0 d-flex justify-content-between flex-grow-1">
        <button class="btn-consent-secondary btn btn-outline-primary" type="submit">Accepter sélection</button>
        <button class="btn-consent-accept btn btn-primary px-4" type="submit" onclick="return consentAcceptAll()">Tout Accepter</button>
      </div>
    </div>
  </form>
</div>
</div>
`;

/**  */
function consentStart() {
  // init data layer
  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    function () {
      window.dataLayer.push(arguments);
    };
  window.gtag("consent", "default", settingsToGTMConsent(consentGetState()));
  window.gtag("set", "ads_data_redaction", false);
  window.gtag("set", "url_passthrough", true);
  if (!consentHasState()) {
    consentDisplayPopup();
  }
}

function consentHasState() {
  return (
    window.localStorage && window.localStorage.getItem(CONSENT_STORAGE_KEY)
  );
}

function settingsToGTMConsent(settings) {
  return {
    // necessary
    personalization_storage: settings.necessary ? "granted" : "denied",
    functionality_storage: settings.necessary ? "granted" : "denied",
    security_storage: settings.necessary ? "granted" : "denied",
    // analytics
    analytics_storage: settings.analytics ? "granted" : "denied",
    // marketing
    ad_storage: settings.marketing ? "granted" : "denied",
    ad_user_data: settings.marketing ? "granted" : "denied",
    ad_personalization: settings.marketing ? "granted" : "denied",
  };
}

function consentGetState() {
  if (consentHasState()) {
    return JSON.parse(window.localStorage.getItem(CONSENT_STORAGE_KEY));
  }
  // by default denied (except necessary)
  return {
    necessary: true,
    analytics: false,
    marketing: false,
  };
}

function consentAcceptAll() {
  // toggle all
  consentUpdateState({
    necessary: true,
    analytics: true,
    marketing: true,
  });
  consentHidePopup();
  return false;
}

function consentLater() {
  consentHidePopup();
  return false;
}

function consentUpdateState(state) {
  window.gtag("consent", "update", settingsToGTMConsent(state));
  if (window.localStorage) {
    window.localStorage.setItem(CONSENT_STORAGE_KEY, JSON.stringify(state));
  }
}

function consentFormSubmit() {
  const form = document.querySelector(".cookie-consent-popup form");
  let settings = {
    necessary: form.necessary.checked,
    analytics: form.analytics.checked,
    marketing: form.marketing.checked,
  };
  consentUpdateState(settings);
  consentHidePopup();
  return false;
}

function consentHidePopup() {
  const popup = document.querySelector(".cookie-consent-popup");
  popup.style.display = "none";
}

function consentDisplayPopup() {
  const popup = document.querySelector(".cookie-consent-popup");
  if (popup) {
    popup.style.display = "block";
  } else {
    const container = document.createElement("div");
    container.innerHTML = html;
    document.body.appendChild(container);
  }
  const settings = consentGetState();
  document.querySelector(
    ".cookie-consent-popup input[name=analytics]",
  ).checked = settings.analytics;
  document.querySelector(
    ".cookie-consent-popup input[name=marketing]",
  ).checked = settings.marketing;
  document.querySelector(".cookie-consent-popup form").onsubmit =
    consentFormSubmit;
  document.querySelector(
    ".cookie-consent-popup button.btn-consent-accept",
  ).onclick = consentAcceptAll;
  document.querySelector(
    ".cookie-consent-popup .btn-cookie-content-later",
  ).onclick = consentLater;
}

setTimeout(consentStart, 500);
window.consentDisplayPopup = consentDisplayPopup;
window.consentLater = consentLater;
window.consentFormSubmit = consentFormSubmit;
