/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//require("jquery/dist/jquery.min.js");
//require("popper.js/dist/popper.min");
//require("bootstrap/dist/js/bootstrap.min.js");
require("./src/scss/styles.scss");
require("./src/gtm-cookie-system.js");
